.success-block {
    position: relative;
    color: rgba(20, 20, 27, 1);
    text-align: center;
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    align-items: center;
    gap: 50px;
    padding: 20px;
    width: 100%;
    background-color: #f5f5f6;
}

.success-block::after {
    content: "";
    display: block;
    background-image: url("../../assets/success-bg.svg");
    background-repeat: no-repeat;
    width: 390px;
    height: 175px;
    position: absolute;
    top: 0;
}

.success-block .success-info,
.success-block .download-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.success-block .download-wrapper {
    gap: 20px;
}