#main-swiper {
      width: 100%;
      max-width: calc(540px + 150px);
      padding: 30px 0 0 0;
}

#main-swiper .swiper-wrapper {
      height: fit-content;
      z-index: 1;
}

#main-swiper .img-large {
      display: block;
      padding: 0 20px;
      max-width: 580px;
      height: auto;
      margin: auto;
      position: relative;
}

#main-swiper .img-large img {
      width: 100%;
      height: auto;
      border-radius: 15px;
}

#main-swiper .img-large .description {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: white;
      text-align: center;
      position: absolute;
      top: 10px;
      left: 50%;
      transform: translate(-50%, 0);
}

#main-swiper .img-large .description .larger {
      font-size: 18px;
      font-weight: 600;
      line-height: 24px;
}

#main-swiper .img-large .description .smaller {
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
}

.swiper-button-next::after {
      content: url("../../../assets/arrow-right.svg") !important;
      position: absolute;
      width: 40px;
      height: 40px;
      bottom: 45%;
      right: 0;
      z-index: 10;
}

.swiper-button-prev::after {
      content: url("../../../assets/arrow-right.svg")  !important;
      transform: rotate(180deg);
      position: absolute;
      width: 40px;
      height: 40px;
      bottom: 45%;
      left: 0;
      z-index: 10;
}

.pagination-card {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 6px;
      margin: 2px;
}

#secondary-swiper {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 10px auto 0 auto;
      padding: 2px 0;
}

#secondary-swiper .img-small {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: auto;
      border-radius: 5px;
      /* margin-left: 15px; */
}

.img-small img {
      width: 102px;
      height: 102px;
      border-radius: 5px;
      padding: 1px;
}

.selection {
      width: 100%;
      display: flex;
      justify-content: center;
}

#blocked-btn,
#select-btn {
      width: fit-content;
      margin: 25px auto;
      padding: 12px 16px;
      color: white;
      border-radius: 25px;
      background-color: #4E84A6;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
}

#blocked-btn {
      color: #14141B;
      background-color: #B8CEDB;
}

.active img {
      box-shadow: 0 0 0 2px rgba(213, 45, 86, 1);
}

@media (max-width: 576px) {
      #main-swiper {
            padding: 0;
      }

      .selection a {
            width: 100%;
      }

      #blocked-btn,
      #select-btn {
            width: 100%;
            margin: 25px 20px;
      }
}